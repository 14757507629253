import React from "react";
import dynamic from "next/dynamic";
import { ServiceFieldsType, RenderMethod } from "types/business.types";
import { PaginateGenericType } from "types/generic.types";
import { useBusinessProfile } from "hooks/useFetch";

const ServicesList = dynamic(() => import("./List.services"), {
	ssr: true,
});

const ServicesCategorized = dynamic(() => import("./Categorized.services"), {
	ssr: true,
});

type Props = { services: PaginateGenericType<ServiceFieldsType> };

export default function ServiceContainer({ services }: Props) {
	const { data: businessProfile } = useBusinessProfile();
	const widgetSetting = businessProfile?.data.widget;
	const renderMethod = widgetSetting?.settings.service.renderMethod;

	if (services.items.length > 0) {
		/* Render method may be categorized (on API) but appears as Plain - Check businessProfile.ssr.ts */
		if (renderMethod === RenderMethod.Categorized) {
			return <ServicesCategorized services={services} />;
		} else if (renderMethod === RenderMethod.Plain) {
			return <ServicesList />;
		} else {
			// No Render
			return null;
		}
	} else {
		return null;
	}
	// else {
	// 	return <EmptyState section="services" />;
	// }
}
